import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TOURS_LIST } from '../../adapters/tours-repository';
import { LinksBlock } from '../components/LinksBlock';
import { TextImageCitationBlock } from '../components/TextImageCitationBlock';
import { ToursList } from '../components/ToursList';
import headerBackgroundImage from '../images/home_page_header_bg_1200.jpg';

export function HomePage(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <Box sx={{ overflowX: 'hidden' }} width="100vw">
      <Box
        component="header"
        display="flex"
        alignItems="center"
        textAlign="center"
        position="relative"
        sx={{
          backgroundImage: `url(${headerBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          width: '106%',
          marginLeft: '-3%',
          paddingLeft: '3%',
          height: '100vh',
          borderRadius: '0 0 100% 100% / 30% 30%',
          marginBottom: '-100px',
        }}
      >
        <Box
          position="absolute"
          height="100vh"
          width="100vw"
          sx={{
            background: 'linear-gradient(180deg, #1e2128 -5.00%, rgba(168,168,168,0 ) 100.00%)',
          }}
        ></Box>
        <Stack width="100vw" color="white" alignItems="center" position="relative">
          <Typography variant="h2" fontSize="24px">
            {t('home.headerSubtitle')}
          </Typography>
          <Typography variant="h1" fontFamily="Nunito Variable" fontSize="64px">
            Alsa Cyclo Tours
          </Typography>
          <ExpandMoreRoundedIcon sx={{ fontSize: '3rem' }} />
        </Stack>
      </Box>

      <Container maxWidth="lg">
        <Stack spacing={15}>
          <LinksBlock
            elements={[
              {
                title: t('menu.items.rent'),
                text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',
                url: t('routing.rent'),
              },
              {
                title: t('menu.items.guidedTours'),
                text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',
                url: t('routing.guidedTours'),
              },
              {
                title: t('menu.items.stays'),
                text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',
                url: t('routing.stays'),
              },
              {
                title: t('menu.items.professionals'),
                text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed',
                url: t('routing.proCompanyTrip'),
              },
            ]}
          ></LinksBlock>

          <TextImageCitationBlock
            title={<Trans i18nKey="home.whoAreWeBlock.title" />}
            text={<Trans i18nKey="home.whoAreWeBlock.text" />}
            image={headerBackgroundImage}
            quotation={t('home.whoAreWeBlock.quotation')}
            buttonText={t('home.whoAreWeBlock.button')}
            buttonUrl={t('routing.ourAgencies')}
          />

          <ToursList title={<Trans i18nKey="home.toursBlock.title" />} tours={TOURS_LIST} />

          <Box height={'1000px'}></Box>
        </Stack>
      </Container>
    </Box>
  );
}
