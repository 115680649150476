import React from 'react';
import { Outlet } from 'react-router-dom';

import { TopBar } from './controllers/components/TopBar';

export function App(): React.JSX.Element {
  return (
    <div className="App">
      <TopBar />
      <Outlet />
    </div>
  );
}
