import i18next from 'i18next';
import React from 'react';
import { createBrowserRouter, type NonIndexRouteObject } from 'react-router-dom';

import { App } from './App';
import { BikesRentalPage } from './controllers/pages/BikesRentalPage';
import { HomePage } from './controllers/pages/HomePage';
import { ToursPage } from './controllers/pages/ToursPage';

function createChildrenRoutesForLanguage(language: string): NonIndexRouteObject[] {
  return [
    {
      path: `${i18next.t('routing.tours', { lng: language })}`,
      element: <ToursPage />,
    },
    {
      path: `${i18next.t('routing.rent', { lng: language })}`,
      element: <BikesRentalPage />,
    },
  ];
}

// Ignore return type ESLint issue
// eslint-disable-next-line
export function buildAppRouter() {
  return createBrowserRouter([
    {
      path: '/',
      element: <App />,
      children: [
        { index: true, element: <HomePage /> },
        ...createChildrenRoutesForLanguage('en'),
        ...createChildrenRoutesForLanguage('fr'),
      ],
    },
  ]);
}
