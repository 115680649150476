import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface LinksBlockElement {
  title: string;
  icon?: string;
  text: string;
  url: string;
}

interface LinksBlockProps {
  elements: LinksBlockElement[];
}

export const LinksBlock: FC<LinksBlockProps> = (props: LinksBlockProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const shouldBeVertical = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ButtonGroup
      variant="contained"
      aria-label="Basic button group"
      orientation={shouldBeVertical ? 'vertical' : 'horizontal'}
    >
      {props.elements.map((element, idx) => (
        <Button
          key={idx}
          component={Link}
          to={t(element.url)}
          sx={{
            backgroundColor: idx % 2 !== 0 ? 'primary.light' : 'primary.main',
            textTransform: 'none',
          }}
        >
          <Box px={2} py={3} textAlign="center">
            <Typography variant={'h2'} sx={{ fontWeight: 'bold', fontSize: 22 }} mb={1}>
              {element.title}
            </Typography>
            <Divider variant="middle" sx={{ borderColor: 'white' }}></Divider>
            <Typography mt={2} variant={'body1'}>
              {element.text}
            </Typography>
          </Box>
        </Button>
      ))}
    </ButtonGroup>
  );
};
