import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function BikesRentalPage(): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <div>
      <Box>{t('menu.items.rent')}</Box>
    </div>
  );
}
