import type { Metric } from 'web-vitals/src/types/base';

export function monitorWebVitals(): void {
  function sendToAnalytics(metric: Metric): void {
    const body = JSON.stringify(metric);
    const url = process.env.ANALYTICS_SERVICE_URL;
    if (url === undefined) {
      console.error('Env var not defined: ANALYTICS_SERVICE_URL');
      return;
    }

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url, body);
    } else {
      void fetch(url, { body, method: 'POST', keepalive: true });
    }
  }

  import('web-vitals')
    .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(sendToAnalytics);
      getFID(sendToAnalytics);
      getFCP(sendToAnalytics);
      getLCP(sendToAnalytics);
      getTTFB(sendToAnalytics);
    })
    .catch((e) => {
      console.error(e);
    });
}
