import '@fontsource/roboto';
import '@fontsource-variable/nunito';
import 'swiper/css';
import 'swiper/css/pagination';

import { createTheme } from '@mui/material/styles';

// Default values: https://mui.com/material-ui/customization/default-theme/
export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1F9847',
      light: '#3FA761',
      dark: '#006728',
    },
    secondary: {
      main: '#FFA530',
      light: '#FFCB83',
      dark: '#FA8B17',
    },
    grey: {
      100: '#F4F4F4',
      300: '#E5E5E5',
      800: '#303030',
    },
    background: {
      paper: '#F4F4F4',
      default: '#F4F4F4',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica Neue", "Arial", "sans-serif"',
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: 'none',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        firstButton: {
          borderRight: 'none',
          borderBottom: 'none',
        },
        middleButton: {
          borderRight: 'none',
          borderBottom: 'none',
        },
      },
    },
  },
});
