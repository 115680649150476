import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import SignalCellularAlt1BarRoundedIcon from '@mui/icons-material/SignalCellularAlt1BarRounded';
import SignalCellularAlt2BarRoundedIcon from '@mui/icons-material/SignalCellularAlt2BarRounded';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Swiper as SwiperClass } from 'swiper';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { type Tour } from '../../domain/entities/tour';
import { CombinedIcons } from './CombinedIcons';

interface ToursListProps {
  title: string | React.JSX.Element;
  tours: Tour[];
}

export const ToursList: FC<ToursListProps> = (props: ToursListProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isOnMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isOnTablet = useMediaQuery(theme.breakpoints.down('md'));

  const NavigationButton = styled(IconButton)({
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '10%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.secondary.dark,
    },
    '&:disabled': {
      borderColor: theme.palette.grey[400],
    },
  });

  const [swiperRef, setSwiperRef] = useState<SwiperClass | undefined>(undefined);
  const [isSwiperBeginning, setSwiperBeginning] = useState<boolean>(true);
  const [isSwiperEnd, setSwiperEnd] = useState<boolean>(false);
  const onSwiperSlideChange = (swiper: SwiperClass): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setSwiperBeginning(swiper.isBeginning);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setSwiperEnd(swiper.isEnd);
  };

  const difficultyLabelsMapper: Record<number, string> = {
    1: t('tours.difficulties.easy'),
    2: t('tours.difficulties.medium'),
    3: t('tours.difficulties.hard'),
  };

  function iconFromDifficulty(difficulty: number): React.JSX.Element {
    if (difficulty === 1) {
      return <SignalCellularAlt1BarRoundedIcon color="secondary" />;
    } else if (difficulty === 2) {
      return <SignalCellularAlt2BarRoundedIcon color="secondary" />;
    } else {
      return <SignalCellularAltRoundedIcon color="secondary" />;
    }
  }

  return (
    <Box>
      <Typography variant="h3" fontFamily="Nunito Variable" fontWeight="bold">
        {props.title}
      </Typography>
      <Box textAlign="right" marginTop="20px" marginBottom="20px">
        <NavigationButton
          onClick={() => {
            swiperRef?.slidePrev();
          }}
          disabled={isSwiperBeginning}
          sx={{ marginRight: '30px' }}
        >
          <ArrowBackRoundedIcon />
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            swiperRef?.slideNext();
          }}
          disabled={isSwiperEnd}
        >
          <ArrowForwardRoundedIcon />
        </NavigationButton>
      </Box>
      <Swiper
        onSwiper={setSwiperRef}
        onSlideChange={onSwiperSlideChange}
        slidesPerView={'auto'}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {props.tours.map((tour, idx) => (
          <SwiperSlide
            key={tour.key}
            style={{ width: isOnMobile ? '85%' : isOnTablet ? '43%' : '28%' }}
          >
            <Box>
              <Box
                borderRadius="15px"
                width="100%"
                height="250px"
                sx={{
                  backgroundImage: `url(${tour.pictureUrl})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                }}
              />
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  padding: '30px 20px',
                  width: '75%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '-50px',
                  marginBottom: '40px',
                }}
              >
                <Typography variant="h4" color="grey" fontSize="1.1rem" mb="10px">
                  {tour.startCity}
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontFamily="Nunito Variable"
                  fontSize="1.7rem"
                  mb="10px"
                >
                  {t(`tours.names.${tour.key}`)}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs="auto">
                    <Stack direction="row" alignItems="center">
                      <HourglassTopRoundedIcon color="secondary" />
                      <Typography variant="body1">{tour.duration}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs="auto">
                    <Stack direction="row" alignItems="center">
                      <CombinedIcons>
                        <SignalCellularAltRoundedIcon sx={{ color: 'lightgrey' }} />
                        {iconFromDifficulty(tour.difficulty)}
                      </CombinedIcons>
                      <Typography variant="body1">
                        {difficultyLabelsMapper[tour.difficulty]}
                      </Typography>
                    </Stack>
                  </Grid>
                  {tour.price !== undefined && (
                    <Grid item xs="auto">
                      <Stack direction="row" alignItems="center">
                        <EuroRoundedIcon color="secondary" />
                        <Typography variant="body1">{tour.price}€</Typography>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
