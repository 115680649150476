import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { type FC } from 'react';
import { Link } from 'react-router-dom';

interface TextImageCitationBlockProps {
  title: string | React.JSX.Element;
  text: string | React.JSX.Element;
  image: string;
  quotation: string;
  buttonText: string;
  buttonUrl: string;
}

export const TextImageCitationBlock: FC<TextImageCitationBlockProps> = (
  props: TextImageCitationBlockProps,
) => {
  // const { t } = useTranslation();

  const theme = useTheme();
  const shouldReduceSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container>
      <Grid item xs={12} md={6} mb="25px" pr={shouldReduceSize ? '0px' : '70px'}>
        <Typography variant="h3" fontFamily="Nunito Variable" fontWeight="bold">
          {props.title}
        </Typography>
        <Typography variant="body1">{props.text}</Typography>
        <Button
          component={Link}
          size="large"
          to={props.buttonUrl}
          endIcon={<ArrowForwardRoundedIcon fontSize="large" />}
          color="secondary"
          variant="contained"
          sx={{ color: 'white', textTransform: 'none' }}
        >
          {props.buttonText}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          component="img"
          sx={{
            width: '100%',
            height: 'auto',
            maxHeight: '100%',
          }}
          alt=""
          src={props.image}
        />
      </Grid>
      <Grid item xs={12} mt={shouldReduceSize ? '-20px' : '-100px'}>
        <Stack
          sx={{
            backgroundColor: 'white',
            maxWidth: shouldReduceSize ? '90%' : '30%',
            marginLeft: 'auto',
            marginRight: 'auto',
            filter: 'drop-shadow(20px 20px 20px rgba(0,0,0,0.09 ))',
          }}
          alignItems="center"
          p="30px"
        >
          <Avatar
            sx={{
              backgroundColor: 'white',
              marginTop: '-45px',
            }}
          >
            <FormatQuoteRoundedIcon color="secondary" />
          </Avatar>

          <Typography
            variant="body1"
            fontSize="1.3rem"
            fontStyle="italic"
            color="grey.800"
            fontFamily="Times New Roman,Arial"
          >
            {props.quotation}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
