import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ContactUsButton: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const shouldReduceSize = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Button
      component={Link}
      to={t('routing.contact')}
      color="secondary"
      sx={{ textTransform: 'none', color: 'inherit' }}
      variant="contained"
    >
      <MailOutlineRoundedIcon sx={shouldReduceSize ? {} : { marginRight: '4px' }} />
      {shouldReduceSize ? '' : t('menu.items.contactus')}
    </Button>
  );
};
