import { SvgIcon } from '@mui/material';
import { type SvgIconOwnProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { type FC } from 'react';

interface CombinedIconsProps extends SvgIconOwnProps {
  children: React.JSX.Element[];
}

export const CombinedIcons: FC<CombinedIconsProps> = (props: CombinedIconsProps) => {
  return (
    <SvgIcon
      fontSize={props.fontSize}
      component="span"
      sx={{
        position: 'relative',
        svg: {
          position: 'absolute',
          left: '0',
          width: '100%',
        },
      }}
    >
      {props.children}
    </SvgIcon>
  );
};
