import cathedraleImage from '../controllers/images/tour_cathedrale.jpg';
import decouverteVignobleImage from '../controllers/images/tour_decouverte_vignoble.jpg';
import kaysersbergImage from '../controllers/images/tour_kaysersberg.jpg';
import routeVinsAlsaceImage from '../controllers/images/tour_route_vins_alsace.jpg';
import { type Tour } from '../domain/entities/tour';

export const TOURS_LIST: Tour[] = [
  {
    key: 'kaysersberg',
    startCity: 'EGUISHEIM',
    isGuided: false,
    duration: '3h30',
    difficulty: 2,
    pictureUrl: kaysersbergImage,
  },
  {
    key: 'routeVinsAlsace',
    startCity: 'EGUISHEIM',
    isGuided: true,
    duration: '1h',
    difficulty: 1,
    price: 45,
    pictureUrl: routeVinsAlsaceImage,
  },
  {
    key: 'cathedrale',
    startCity: 'STRASBOURG',
    isGuided: false,
    duration: '5h30',
    difficulty: 3,
    price: 79,
    pictureUrl: cathedraleImage,
  },
  {
    key: 'decouverteVignoble',
    startCity: 'EGUISHEIM',
    isGuided: true,
    duration: '2h15',
    difficulty: 2,
    pictureUrl: decouverteVignobleImage,
  },
];
