import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Button, Menu, MenuItem, SvgIcon, useMediaQuery, useTheme } from '@mui/material';
import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSelector: FC = () => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const changeLanguage = (newLanguage: string): void => {
    void i18n.changeLanguage(newLanguage);
    handleClose();
  };

  const theme = useTheme();
  const shouldReduceSize = useMediaQuery(theme.breakpoints.down('md'));

  const languages: Record<string, { label: string; flag: React.JSX.Element }> = {
    en: {
      label: 'English',
      flag: (
        <SvgIcon>
          <svg viewBox="0 0 640 480">
            <path d="M0 0h640v480H0z" fill="#012169" />
            <path
              d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"
              fill="#FFF"
            />
            <path
              d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"
              fill="#C8102E"
            />
            <path d="M241 0v480h160V0zM0 160v160h640V160z" fill="#FFF" />
            <path d="M0 193v96h640v-96zM273 0v480h96V0z" fill="#C8102E" />
          </svg>
        </SvgIcon>
      ),
    },
    fr: {
      label: 'Français',
      flag: (
        <SvgIcon>
          <svg viewBox="0 0 640 480">
            <path d="M0 0h640v480H0z" fill="#fff" />
            <path d="M0 0h213.3v480H0z" fill="#000091" />
            <path d="M426.7 0H640v480H426.7z" fill="#e1000f" />
          </svg>
        </SvgIcon>
      ),
    },
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
        endIcon={<ExpandMoreRoundedIcon />}
        sx={{
          textTransform: 'none',
          minWidth: shouldReduceSize ? '0' : '150px',
          textAlign: 'left',
        }}
      >
        {languages[i18n.language]?.flag}
        &nbsp;
        {shouldReduceSize ? '' : `${languages[i18n.language]?.label}`}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.entries(languages).map(
          ([languageId, language]) =>
            languageId !== i18n.language && (
              <MenuItem
                key={languageId}
                onClick={() => {
                  changeLanguage(languageId);
                }}
              >
                {language.flag}&nbsp;
                {language.label}
              </MenuItem>
            ),
        )}
      </Menu>
    </div>
  );
};
