import './index.css';
import './adapters/i18n/i18n';

import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { monitorWebVitals } from './adapters/monitor-web-vitals';
import { theme } from './controllers/theme';
import { buildAppRouter } from './routing';

const domNode: HTMLElement | null = document.getElementById('root');
if (domNode === null) {
  throw new Error('Failed to find "root" element in DOM');
}
const root = ReactDOM.createRoot(domNode);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={buildAppRouter()} />
    </ThemeProvider>
  </React.StrictMode>,
);

monitorWebVitals();
