import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  AppBar,
  Box,
  Collapse,
  Container,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import actLogo from '../images/logo_alsa_cyclo_tours_bg_white_150.png';
import { ContactUsButton } from './ContactUsButton';
import { LanguageSelector } from './LanguageSelector';

export const TopBar: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isOnMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isOnTablet = useMediaQuery(theme.breakpoints.down('md'));

  const hasScrolledDownAfterHeader = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight * 0.75,
  });

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const [openedMenu, setOpenedMenu] = React.useState<string | null>(null);
  const toggleOpenedMenu = (menuToToggle: string): void => {
    if (openedMenu === menuToToggle) {
      setOpenedMenu(null);
    } else {
      setOpenedMenu(menuToToggle);
    }
  };
  const menuElements = [
    {
      label: t('menu.items.home'),
      path: '/',
    },
    {
      label: t('menu.items.rent'),
      children: [
        {
          label: t('menu.items.rent'),
          path: t('routing.rent'),
        },
      ],
    },
    {
      label: t('menu.items.guidedTours'),
      path: t('routing.guidedTours'),
    },
    {
      label: t('menu.items.stays'),
      path: t('routing.stays'),
    },
    {
      label: t('menu.items.professionals'),
      children: [
        {
          label: t('menu.items.proCompanyTrip'),
          path: t('routing.proCompanyTrip'),
        },
        {
          label: t('menu.items.proTourOperators'),
          path: t('routing.proTourOperators'),
        },
        {
          label: t('menu.items.proLongTermRental'),
          path: t('routing.proLongTermRental'),
        },
      ],
    },
    {
      label: t('menu.items.bikesSales'),
      path: t('routing.bikesSales'),
    },
    {
      label: t('menu.items.about'),
      children: [
        {
          label: t('menu.items.proCompanyTrip'),
          path: t('routing.proCompanyTrip'),
        },
      ],
    },
  ];

  return (
    <div>
      <AppBar
        sx={{
          boxShadow: 0,
          bgcolor: hasScrolledDownAfterHeader ? 'white' : 'transparent',
          color: hasScrolledDownAfterHeader ? 'black' : 'white',
          backgroundImage: 'none',
          position: 'fixed',
          textAlign: 'left',
        }}
      >
        {(!hasScrolledDownAfterHeader || !isOnTablet) && (
          <Box
            width="100vw"
            height="150px"
            position="absolute"
            alignItems="center"
            textAlign="center"
            mt="20px"
          >
            <Box
              component="img"
              src={actLogo}
              alt="Alsa Cyclo Tours logo"
              height="100px"
              width="100px"
            />
          </Box>
        )}
        <Toolbar>
          <IconButton edge="start" size="small" aria-label="menu" onClick={toggleDrawer(true)}>
            <SvgIcon fontSize="large">
              <svg width="800px" height="800px" fill="none" viewBox="0 0 24 24">
                <path
                  d="m5 17h8m-8-5h14m-14-5h8"
                  stroke={hasScrolledDownAfterHeader ? '#1f9847' : 'white'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </SvgIcon>
          </IconButton>

          <Box flexGrow={1}></Box>

          {hasScrolledDownAfterHeader && isOnTablet && (
            <Typography variant="h6" component="div" flexGrow={1} fontFamily="Nunito Variable">
              Alsa Cyclo Tours
            </Typography>
          )}

          {!isOnMobile && <LanguageSelector />}
          <ContactUsButton />
        </Toolbar>
      </AppBar>

      <Drawer open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: isOnTablet ? '100vw' : '50vw', maxWidth: '100vw', height: '100vh' }}
          role="presentation"
          p={'20px'}
        >
          <Grid
            container
            direction={isOnTablet ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Grid item container>
              <Grid item xs={3}>
                <IconButton size="small" aria-label="close menu" onClick={toggleDrawer(false)}>
                  <CloseRoundedIcon fontSize="large" color="primary" />
                </IconButton>
              </Grid>
              <Grid item xs={6} alignItems="center" textAlign="center">
                <Box
                  component="img"
                  src={actLogo}
                  alt="Alsa Cyclo Tours logo"
                  width="100px"
                  height="100px"
                />
              </Grid>
              <Grid item xs={3} textAlign="right">
                <LanguageSelector />
              </Grid>
            </Grid>

            <Grid item flexGrow="1">
              <Container maxWidth="xs">
                <List>
                  {menuElements.map((menuElt, idx) => (
                    <div key={idx}>
                      <ListItemButton
                        component={RouterLink}
                        to={menuElt.path ?? ''}
                        sx={
                          openedMenu === menuElt.label
                            ? {
                                color: theme.palette.primary.main,
                                borderBottom: `1px solid ${theme.palette.primary.main}`,
                              }
                            : {}
                        }
                        onClick={() => {
                          if (menuElt.path !== undefined) {
                            setDrawerOpen(false);
                          } else {
                            toggleOpenedMenu(menuElt.label);
                          }
                        }}
                      >
                        <ListItemText
                          primary={menuElt.label}
                          primaryTypographyProps={{
                            fontSize: isOnTablet ? '1rem' : '2rem',
                            fontWeight: openedMenu === menuElt.label ? 'bold' : 'inherit',
                          }}
                        />
                        {menuElt.children !== undefined &&
                          (openedMenu === menuElt.label ? (
                            <ExpandLessRoundedIcon />
                          ) : (
                            <ExpandMoreRoundedIcon />
                          ))}
                      </ListItemButton>
                      {menuElt.children !== undefined && (
                        <Collapse in={openedMenu === menuElt.label} timeout="auto" unmountOnExit>
                          <List component="div">
                            {menuElt.children?.map((childrenElt, childIdx) => (
                              <ListItemButton
                                key={`${idx}-${childIdx}`}
                                sx={{ pl: 4 }}
                                component={RouterLink}
                                to={childrenElt.path}
                                onClick={() => {
                                  setDrawerOpen(false);
                                }}
                              >
                                <ListItemText
                                  primary={childrenElt.label}
                                  primaryTypographyProps={{
                                    fontSize: isOnTablet ? '0.8rem' : '1.3rem',
                                  }}
                                />
                              </ListItemButton>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </div>
                  ))}
                </List>
              </Container>
            </Grid>

            <Grid item container alignItems="center">
              <Grid item xs={12} md={6}>
                <IconButton
                  size="small"
                  aria-label="Alsa Cyclo Tours' Facebook page"
                  href="https://www.facebook.com/Alsa-Cyclo-Tours-304928796329452"
                  target="_blank"
                >
                  <FacebookIcon fontSize="large" color="primary" />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="Alsa Cyclo Tours' Instagram page"
                  href="https://www.instagram.com/alsacyclotours/"
                  target="_blank"
                >
                  <InstagramIcon fontSize="large" color="primary" />
                </IconButton>
              </Grid>
              <Grid item xs={12} md={6} textAlign="right">
                <Link
                  component={RouterLink}
                  to={t('routing.terms')}
                  underline="hover"
                  color="black"
                  fontSize={isOnTablet ? '10px' : '16px'}
                >
                  {t('menu.items.terms')}
                </Link>
                &nbsp;-&nbsp;
                <Link
                  component={RouterLink}
                  to={t('routing.conditionsOfUse')}
                  underline="hover"
                  color="black"
                  fontSize={isOnTablet ? '10px' : '16px'}
                >
                  {t('menu.items.conditionsOfUse')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};
